






// eslint-disable-next-line
// @ts-ignore Нет типов для пакета
import NoSSR from 'vue-no-ssr';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'NoSSR',
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ['no-ssr']: NoSSR,
  },
})
export default class NoServerRendering extends Vue {}
